import logo from './nos2.png';
import './App.css';

function App() {
  document.title = 'NAKED';
  return (
    
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" className="img" />
      </header>
    </div>
  );

}

export default App;
